<template>
  <div class="footer">
    <!-- <img src="../assets/logo.png" class="logo" alt="" /> -->
    <div class="footer-box">
      <div class="adress">
      <p>
        Copyright 2004-2023 A8.COM All rights reserved.
      </p>
      <p>
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >粤ICP备13012456号-5</a
        >
        <!-- <a href="https://beian.miit.gov.cn/" target="_blank"
          >备案信息参照：京ICP备14010450号-1</a
        > -->
      </p>
      <p class="tes">
        <img :src="require('@/assets/weitu.png')" alt="">
        <a target="_blank" :href="require('../assets/img.png')"
          >粤网文[2011]0391-058号</a
        >
      </p>
      <a target="_blank" :href="require('../assets/bc.png')">
        <img  class="wentu2" :src="require('@/assets/weitu2.png')" alt="">

      </a>
      <!-- <p>
        <a :href="require('../assets/lg.png')" target="_blank"
          >出版物经营许可证</a
        >
        新出发京零字第朝 200281号
      </p> -->
      <!-- <p>
        <a
		    :href="require('../assets/bc.png')"
          target="_blank"
          >网络文化经营许可证 京网文（2021）</a
        >
        0463-113号
      </p> -->
      <!-- <p class="flex">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802028299">
          <img
            src="https://b-new.heiyanimg.com/img/1551417002682_1167767149.png"
            alt=""
          />

          京公网安备 11010802028299号
        </a>
      </p> -->
      <!-- <p>
        <a :href="require('../assets/zb.png')" target="_blank"
          >北京掌文信息技术有限公司地址：</a
        >
		北京市朝阳区紫月路18号院4号楼6层506室
      </p> -->
    </div>
    <div class="footer-right">
       <p>
        <a target="_blank" href="http://main.a8-img.com/static/2011/images/web/a8-dxxk.jpg?v=2015">
          增值业务许可证B2-20090206
        </a>
       </p>
       <p>
        <a target="_blank" href="http://main.a8-img.com/static/2011/images/web/stxk.jpg?v=2016">
          网络视听许可证1909352号
        </a>
       </p>
       <p>
        <a target="_blank" :href="require('../assets/bc.png')">
          互联网出版许可证：新出网证(粤)字056号
        </a>
       </p>
       <p class="gongan">
        <img :src="require('@/assets/weitu5.png')" alt="">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000397" target="_blank"
          >粤公网安备 44030502000397号</a
        >
      </p>
    </div>
    </div>
    
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  },
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>
<style lang="scss" scoped="scoped" type="text/css">
.footer {
  background-color: #211d1d;
  padding:20px 0px  70px 0;
  margin-top: 100px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  .footer-box{
    margin-left: 450px;
    display: flex;
    justify-content: space-between;
  }
  .footer-right{
    margin-left: 400px;
    display: flex;
    flex-flow: column;
    gap:8px 0px;
    font-size: 12px;
    a {
      line-height: 22px;
      font-size: 12px;
      color: #999999;
    }
  }
  .logo {
    height: 56px;
    margin-bottom: 14px;
  }
  .adress {
    display: flex;
    flex-flow: column;
    gap:4px 0px;
    p,
    a {
      line-height: 22px;
      font-size: 12px;
      color: #999999;
    }
  }
}
.flex {  
  img {
	display: inline-block;
    vertical-align: top;
    height: 18px;
  }
}
a {
  text-decoration: none;
}
.tes{
  display: flex;
  align-items: center;
 img{
  margin-right: 10px;
  width: 30px;
  height: 30px;
 }
}
.wentu2{
  height: 30px;
  margin-top: 10px;
  margin-left: 2px;
}
.gongan{
  display: flex;
  align-items: center;
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
</style>
