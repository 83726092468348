// import Vue from 'vue'   //引入Vue
import {
	createRouter,
    createWebHistory
} from 'vue-router' //引入vue-router
// Vue.use(Router)  //Vue全局使用Router
 
import home from '@/views/home.vue'
import detail from '@/views/detail.vue'
import user from '@/views/user.vue'
 
 
const routes = [
	{
		path: '',
		redirect: "home"
	},
	{
		path: '/home',
		name: 'home',
		component: home,
		meta: {
			title: '七悦剧场'
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: detail,
		meta: {
			title: '详情'
		}
	},
	{
		path: '/user',
		name: 'user',
		component: user,
		meta: {
			title: '个人中心'
		}
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/404',
		children: [
		  {
			path: '/404', // 这里要加上/404,和上面的redirect对应,必须加上/
			name: '404',
			component: () => import('@/components/404'),
		  },
		],
	  },
];
 
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
/* router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next();
	} else {
		let token = localStorage.getItem('Authorization');
		if (token === null || token === '') {
			next('/login');
		} else {
			next();
		}
	}
}); */
const router = createRouter({
	history: createWebHistory(),
	routes
})
export default router;