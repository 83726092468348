
<template>
	<div>
		<div class="container">
		<div class="detail">
			<div :class="!isMovie?'detail-video':'detail-video detail-video2'" @mouseover="handlehover" @mouseout="handlehover" @mousemove="setmousemove"  @mouseup="setmouseup">
				<div class="popular" v-if="isJs">试看结束~</div>
				<div class="popular" v-if="isPopular">欢迎试看~</div>
				<video ref="veo" v-if="isFree"
				:playsInline='true'
				controlslist="nodownload"
				:x5-playsinline="true"
				:controls="true"
				@timeupdate="videoUpdate"
				:src="curInfo.resource" :autoplay="true" @play="handlePlay" @ended="handleope('next')"></video>
				
			</div>
		</div>
		<div class="list">
			<div class="list-box" @click="handleGo(index+1)"  v-for="(item,index) of list" :key="index">
				<img class="list-box-img" :src="item.img" alt="图片">
				<p class="list-box-text">{{ item.duration }}</p>
			</div>
		</div>
		<Model :type='1' v-show="ismodel" @closeChild="clickEven"/>
		<payModel :numberTip='numberTip' @openNpmber="openNpmber" v-if="numberTip"></payModel>
		<Ptoast v-show="msg" :msg='msg'/>
	</div>
		<div class="spac-text">{{text}}</div>
	</div>
	
</template>
<script type="text/javascript">
	import Cookies from 'js-cookie'
	import router from '../router/index'
	import {movieApi,movieInfApi,addfavoritfoApi,orderStatus,useInfoApi,tuijianApi} from '@/utils/api'
	import {getToken,removeToken} from '../utils/utils'
	import Ptoast from '../components/ptoast.vue'
    import Vlist from '../components/vlist.vue'
	import Model from '../components/model'
	import payModel from "../components/payModel";
	let flTime = 0
	let setInt = null
	export default {
		data(){
			return{
				numberTip:false,//会员付款弹窗
				ismodel:false,
				ysc:require('../assets/icon_ysc.png'),
				wsc:require('../assets/icon_wsc.png'),
				videoId:0,
				cureid:'',
				curnum:0,
				isFree:true,
				infoData:{},
				curInfo:{},
				videoList:[],
				iconList:[
					// {img:require('@/assets/icon_prev.png'),type:'prev'},
					{type:'center'},
					// {img:require('@/assets/icon_next.png'),type:'next'}
				],
				originPlay: true,
      			vplay: false,
				homeVideo:null,
				duration:0,//总时长
				currentTime:0,//显示播放到的当前时间
				progressWidth:0,////滑动到位置的宽度
				moveType:'',//移动的类型，用来标明是否在拖动进度条
				moveOffsetX:0,//拖动开始时点击的位置
			 	curWidth:0,//拖动开始时进度条的宽度
				setSwitch:false,//是否长按
				setWidth:0,
				msg:'',
				navInfoList : [],
				category:'',
				isMovie:false,
				isPopular:false,
				isJs:false,
				list:[{name:'飞吧，冰上之光',img:require('@/assets/one.png'),ship:2115,duration:'01:26:42'},
					{name:'欢迎来到瑜伽村',img:require('@/assets/two.png'),ship:1986,duration:'01:11:39'},
					{name:'神兵特攻',img:require('@/assets/three.png'),ship:8173,duration:'01:28:29'}],
					text:'',
				}
		},
		components:{
			Ptoast,
			Vlist,
			Model,
			payModel
		},
		watch:{
			$route(to){
				// this.initData()
			},
			$route:{
				handler({query:{id}}){
					this.isJs = false
					this.isFree = true
					this.text=this.list[id-1].name
					this.curInfo={resource:id==2?'https://img.zhangwenjiandu.com/system/a8/welcometoyujiacun1.mp4':id==3?'https://img.zhangwenjiandu.com/system/a8/sbtg1.mp4':'https://img.zhangwenjiandu.com/system/a8/fbbszg1.mp4'}
				},
				immediate:true
			}
		},

		mounted(){
			let {isMovie,id}=this.$route.query
			this.isMovie=isMovie
			// this.initData()
		},
		unmounted(){
		},
		methods:{
			 handleGo(val){
				router.replace({path: '/detail',query: {id:val,isMovie:true}},true)
			},
			//开通会员点击事件
			openNpmber(){
				if(this.numberTip == false)
				{
					this.numberTip = true
				}
				else
				{
					this.numberTip = false
				}
			},
			handleTopay(){
				let token = getToken()
				if(token){
					this.openNpmber()
				}else{
					this.ismodel = true
				}
			},
			clickEven(){
				this.ismodel = false
			},
			initData(){
				let {id,eid,out_trade_no,category} = router.currentRoute.value.query
				// const {width } = document.getElementById('control').getBoundingClientRect()
				this.setWidth =  document.getElementById('control').getBoundingClientRect().width
				this.originPlay = false
				if(id){
					this.videoId = id
				}
				
				if(out_trade_no) //如果订单号存在
				{
					orderStatus({'id': out_trade_no}).then((res)=>{
						console.log(res)
						if(res.paid || res.paid == 'true')
						{
							this.getUserInfo()
						}
					})
				}
				this.homeVideo = this.$refs.veo
				this.homeVideo.currentTime = 0
				this.progressWidth = 0
				movieApi({id}).then((res)=>{
					this.infoData = res
					this.videoList = Object.values(res.episodes)
					document.title = res.name
					if(res.latest_episode_id > 1){
						this.cureid = res.latest_episode_id
						this.curnum = this.videoList.map(item => item).indexOf(res.latest_episode_id)
					}else{
						if(eid){
							this.cureid = eid
							this.curnum = this.videoList.map(item => item).indexOf(eid)
						}else{
							this.cureid = this.videoList[0]
							this.curnum = 0
						}
						
					}
					this.getMovieInf()
				})
				tuijianApi(({category:category})).then((res)=>{
					this.category = category
					this.navInfoList = res
				})

				this.homeVideo.addEventListener('timeupdate', (e)=> { //播放开始执行的函数
					if(this.originPlay && !this.setSwitch){
						if(this.setWidth == 0){
							this.setWidth =  document.getElementById('control').getBoundingClientRect().width
						}
						let width = this.setWidth
						let rat = this.homeVideo.currentTime / this.homeVideo.duration
						this.progressWidth = width * rat
					}
				//执行开始播放的路平级
				})

			},
			getUserInfo(){
				useInfoApi().then((res)=>{
					let info = JSON.parse(res)
					localStorage.setItem('info',info)
				})
			},
			handleStatu(){
				addfavoritfoApi({movie_id:this.videoId}).then((res)=>{
					this.infoData.favorite = !this.infoData.favorite

				}).catch(()=>{
					this.msg = '登录过期'
					setTimeout(()=>{
						this.msg = ''
					},3000)
				})
			},
			handleope(type){
				if(type == 'prev'){
					if(this.curnum == 0){
						return
					}
					this.curnum = this.curnum-1
				}else if(type == 'next'){
					if(this.curnum == this.videoList.length - 1){
						return
					}
					this.curnum = this.curnum+1
				}
				this.cureid = this.videoList[this.curnum]
				this.getMovieInf()
			},
			handleChange(id,index){
				this.cureid = id
				this.curnum = index
				this.getMovieInf()
			},
			getMovieInf(){
				window.scrollTo(0, 0);
				
				movieInfApi({id:this.videoId,episode_id:this.cureid}).then((res)=>{
					this.curInfo = res
					this.isFree = res.free
					if(!res.free){
						this.handlePause()
					}else{
						let videohis = Cookies.get('videohis')
						let hisArr = []
						let hisNum = -1
						let hisSing = [] 
						if(videohis){
							hisArr = JSON.parse(videohis)
							hisNum = hisArr.findIndex((item) => {
								return item.id == this.videoId
							})
						}
						hisSing = {id: this.videoId,eid:this.cureid,name:this.infoData.name,latest_episode_text:`观看至第${res.sequence}集`,cover:this.infoData.cover}
						if(hisNum >= 0){
							hisArr.splice(hisNum,1,hisSing)
						}else{
							if(hisArr.length >= 4){
								hisArr.pop()
							}
							hisArr.unshift(hisSing)
						}
						Cookies.set('videohis',JSON.stringify(hisArr))
					}
				}).catch((res)=>{
					this.curInfo = {cover: "",description: "",duration: 0,episodes_id: 100450003,favorite: false,favorites_count: 0,follow: false,forced_follow_mode: 2,forced_follow_switch: 1,forced_follow_type: 1,free: true,id: 801,movie_id: 10045,movie_status: 0,name: "第3集",price: 40,resource: "https://img.zhangwenjiandu.com/movies/10045/100450003.mp4",sequence: 3,shared_count: 0}
					if(res.code == 4042){
						return
					}
				})

			},
			about(){
				this.$router.push('/about')
			},
			handlehover(){
				this.originPlay = !this.originPlay
			},
			videoPlay(){
				this.vplay = true
			},
			handlePlay(){
				this.vplay = true
				this.$refs.veo.play()
			},
			handlePause(){
				this.vplay = false
				this.$refs.veo.pause()
			},
			//得到视频的时长等信息
			getVideoInfo(){
				this.duration = this.formatTime(parseInt(this.homeVideo.duration));
			},
			//得到视频当前播放到哪里的信息
			getCurrentInfo(){
				// 视频已经播放时长
				this.currentTime = this.formatTime(parseInt(this.homeVideo.currentTime));

				//计算得到宽度:
				let ratio = this.homeVideo.currentTime / this.homeVideo.duration;
				//console.log("ratio:"+ratio);
				let allWidth = document.getElementById('control').getBoundingClientRect().width;
				//console.log("allwidth:"+allWidth);
				this.progressWidth = allWidth * ratio;
				//console.log("progressWidth:"+progressWidth.value);
			},
			// 格式化时间函数
			formatTime(s){
				var t;
				if(s > -1) {
					var hour = Math.floor(s / 3600);
					var min = Math.floor(s / 60) % 60;
					var sec = s % 60;
					if(hour < 0 || hour == 0) {
					t = '';
					} else if(0 < hour < 10) {
					t = '0' + hour + ":";
					} else {
					t = hour + ":";
					}

					if(min < 10) {
					t += "0";
					}
					t += min + ":";
					if(sec < 10) {
					t += "0";
					}
					t += sec;
				}
				return t;
			},

			//设置进度条的长度
			setProgress(e){
				e.preventDefault()
				const {left, width } = document.getElementById('control').getBoundingClientRect()
				if(left){
					// left: 进度条容器control到最左侧的距离
					//15: 按钮宽度的一半
					const proWidth = e.clientX - left
					this.progressWidth = proWidth;

					this.updadteCurrentTime(this.progressWidth, this.setWidth);
				}


			},
			//设置视频播放到指定的长度
			updadteCurrentTime(progressWidth, width){
				let dest = (progressWidth / width) *  this.homeVideo.duration;
				//console.log("dest:"+dest);
				// console.log('dest',dest,width)
				this.homeVideo.currentTime = Math.floor(dest);
				this.$refs.veo.play()
				
			},
			setmouseup(){
				if(this.setSwitch)
				{
					this.updadteCurrentTime(this.progressWidth, this.setWidth);
					this.setSwitch = false
				}

			},
			setmousemove(e){
				// console.log(e)
				// e.preventDefault()
				if(this.setSwitch)
				{
					// const {left, width } = document.getElementById('control').getBoundingClientRect()
					// this.homeVideo.play()
					this.$refs.veo.pause()
					this.progressWidth = e.offsetX > this.setWidth ? this.setWidth : e.offsetX
				}
				// console.log('正在拖动',e)

			},
			//当开始触摸开始在圆点按下时
			sliderStart(e){
				e.preventDefault();
				// this.moveOffsetX = e.touches[0].clientX;
				this.moveType = "slider";
				// this.curWidth = this.progressWidth;
				this.setSwitch = true

			},
			//当触摸在controls上移动时
			controlMove(e){
				if (this.moveType !== 'slider') {
					return false;
				}
				e.preventDefault()

				// 滑动距离可视区域左侧的距离
				const X = e.touches[0].clientX
				//得到本次拖动已经过的距离
				const cl = X - this.moveOffsetX;
				//容器的宽度
				const {width } = document.getElementById('control').getBoundingClientRect()
				//得到已拖动到的宽度
				const ml =  this.curWidth + cl
				let proWidth
				if (ml <= 0) {
					//进度条长度最小和最大值的界定
					proWidth = 0
				} else if (ml >= width) {
					proWidth = width
				} else {
					proWidth = ml
				}
				this.progressWidth = proWidth;
				// 更新当前时间
				updadteCurrentTime(this.progressWidth, width);
			},

			//滑动结束
			controlEnd(){
				this.moveType = "";
			},
			//获取视频播放时间
			videoUpdate(){
				if(!this.isFree) return
				if(~~this.$refs.veo.currentTime>2){
					this.isPopular=false
				}else if(this.$refs.veo.currentTime>0.1){
					this.isPopular=true
				}
				if(~~this.$refs.veo.currentTime>600){
					this.isJs=true
					this.isFree=false
				}
			}
		}
	}
</script>
<style lang="scss" scoped="scoped" type="text/css">
	.play-sta{
		background: url('../assets/icon_bf.png') no-repeat center center/ 100% 100%;
	}
	.pause-sta{
		background: url('../assets/icon_zt.png') no-repeat center center/ 100% 100%;
	}
	.control-statu{
		position: absolute;
		right: 5%;
		bottom: 47%;
		img{
			width: 42px;
			height: 42px;
		}
	}
	.detail-video{
		width: 37vw;
		margin-left: 150px;
		min-width: 320px;
		height: 406px;
		position: relative;
		background-color: black;
		.nofree-con{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000000;
			.nofree-txt{
				width: 229px;
				height: 66px;
				font-size: 21px;
				text-align: center;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
				margin: 60% auto 30px;
			}
			.nofree-btn{
				cursor: pointer;
				width: 148px;
				height: 44px;
				line-height: 44px;
				margin: 0 auto;
				text-align: center;
				background: linear-gradient(270deg, #FF322B 0%, #FF6E6E 100%);
				border-radius: 22px;
				font-size: 12px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
		
		video{
			width: 100%;
			height: 100%;
			background-color: #000000;
		}
		.video-tit {
			width: 100%;
			height: 120px;
			padding-top: 20px;
			font-size: 1rem;
			color: #ffffff;
			background: linear-gradient(180deg, #000000 0%, transparent 100%);
			text-align: center;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 10;
		}
		.video-control{
			width: 100%;
			height: 120px;
			padding:0 1% 0;
			background: linear-gradient(0deg, #000000 0%, transparent 100%);
			text-align: center;
			box-sizing: border-box;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 10;
			
		}
	}

	.detail-info{
		flex: 1;
    	padding: 80px 75px 80px 60px;
		.info-tit{
			font-size: 30px;
			margin-bottom: 13px;
			color: #FFFFFF;
		}
		.info-status{
			font-size: 16px;
			margin-bottom: 13px;
			color: #FFFFFF;
		}
		.info-des{
			font-size: 16px;
			font-weight: 100;
			margin-bottom: 18px;
			display: flex;
			span{
				color: #FFFFFF;
			}
		}
		.info-more{
			margin-bottom: 23px;
			display: flex;
			>P{
				display: flex;
				align-items: center;
				font-size: 16px;
				color: #FF2A2A;
				img{
					width: 23px;
					height: 23px;
					margin-right: 9px;
				}
			}
		}
	}
	.info-menu{
		display: flex;
    	flex-wrap: wrap;
		.menu-item{
			width: 51px;
			height: 51px;
			font-size: 20px;
			border: 1px solid #ffffff;
			border-radius: 6px;
			margin: 5px;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			position: relative;
			.item-pay,.item-free{
				position: absolute;
				top: 0;
				right: 0;
				width: 15px;
				height: 15px;
			}
			.item-free{
				width: 15px;
				height: 15px;
				top: initial;
				right: 6px;
				bottom: 6px ;
			}
		}
		.item-active{
			background: #FF2A2A;
			border-radius: 6px;
			border: none;
		}
	}
	.control {
		background-color: #999999;
		height: 5px;
		position: relative;
		z-index: 9;
		margin-bottom: 20px;
	}
	.slider_circle {
		position: absolute;
		width:15px;
		height: 15px;
		border-radius: 10px;
		border: 1px;
		background: #ffffff;
		margin-top: -15px;
		top:10px;
		cursor: pointer;
	}

	.slider_circle_large {
		position: absolute;
		width:50px;
		height: 50px;
		border-radius: 25px;
		border: 1px;
		background: #FFFFFF;
		opacity: 0.8;
		top: -20px;
		left:-20px;
	}
	.detail-recom{
		// padding: 0 320px 100px;
		background-color: #0d0e1d;
		.detail-recomtit{
			padding: 30px 0 10px;
			margin-bottom: 10px;
			font-size: 24px;
			color: #FF2A2A;
			border-bottom: 1px solid #FF2A2A;
		}
	}
	.popular{
		position: absolute;
		left: 50%;
		top: 10%;
		color: white;
		font-size: 30px;
		transform: translate(-50%,-50%);
		z-index: 1;
	}
	.control-list{
				width: 0%;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				top: 40%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
			.control-item{
				img,p{
					width: 36px;
					height: 36px;
					display: block;
				}
			}
			.container{
				display: flex;
				background: #2c2c34;
				margin-top: 30px;
				padding: 0 300px 0;
				.list{
					flex-shrink: 0;
					margin-left: 30px;
					margin-right: 100px;
					.list-box{
						margin-bottom: 20px;
						position: relative;
						 &-img{
							width: 15vh;
							height: 8vh;
						 }
						 &-text{
							position: absolute;
							color: white;
							left: 10px;
							bottom: 10px;
							padding: 2px;
							font-size: 12px;
							background: rgba(0,0,0,0.5);
						 }
					}
				}
			}
			.spac-text{
				padding:10px 0 10px 450px;
				background: #2c2c34;
				color: white;
				font-size: 28px;
				color: #ccc;
			}
</style>
