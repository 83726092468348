<template>
  <div id="qy">
    <Header @closeChild="clickEven" :info='uInfo'/>
    <router-view/>
    <Footer/>
    <Model :type='1' v-show="ismodel" @closeChild="clickEven"/>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect } from "vue";
import Header from '@/components/header'
import Footer from '@/components/footer'
import Model from '@/components/model'
import { useRoute } from 'vue-router';

let ismodel = ref(false)
let uInfo = ref({})
const route = useRoute();
const clickEven=(val)=>{
  ismodel.value = val.statu == 'false' ? false : true
  uInfo.value = val.info
}


</script>

<style>
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
body{
	background-color: #FFFFFF;
  font-family: PingFangSC-Medium, PingFang SC;
}
#qy{
  width: 100%;
  min-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
  background: #2c2c34;
}
#qy3{
  width: 100%;
  min-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
  background: #211d1d;
}
img{
  display: block;
}
.elltext2 {
  overflow: hidden ;
  white-space: pre-wrap;
  display: -webkit-box ;
  text-overflow: ellipsis;
  word-break: break-all; 
  -webkit-line-clamp:2;    
  -webkit-box-orient: vertical;
}

.elltext {
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}
.actColor{
  color: #FF2A2A !important;
}

</style>
