<template>
	<ul class="navinfo-list">
        <li v-for="(item,index) in dataList" :key="index" class="vitem-item" @click="toDetail(item)">
            <div class="vitem-cover">
                <img :src="item.cover" alt="">
                <p class="vitem-tag" v-show="type != 'detail'">{{item.episode_total}}集{{item.status ? '完结' : '连载中'}}</p>
            </div>
            <p class="vitem-name elltext">{{item.name}}</p>
            <p class="vitem-desc elltext2" v-show="type != 'detail'">{{item.description}}</p>
            <p class="vitem-desc elltext2" v-show="type == 'detail'">{{item.status ? '已完结' : '连载中'}},共{{item.episode_total}}集</p>
        </li>
    </ul>
</template>

<script setup>
	import { toRefs,toRaw } from "vue";
    import {useRouter} from'vue-router'
	const router = useRouter()
    const props = defineProps({
        dataList:Array,
        category:Number,
        type:String
    })
    let toDetail = (item)=>{
        router.push({path: '/detail',query: {id:item.id,category:props.category}})
    }
    // console.log(props,toRaw(props))
    // let pmsg = ref('')
    // pmsg.value = msg;
    // watch(pmsg, (newValue, oldValue) => {
    //     if(newValue != oldValue){
    //         setTimeout(()=>{
    //             pmsg.value = ''
    //             console.log(pmsg.value)
    //         },3000)
    //     }
    // });
    
    // onMounted(() => {clearInterval(clearId)})
</script>

<style lang="scss" scoped>
    .navinfo-list{
            display: flex;
            flex-wrap: wrap;

            .vitem-item{
                width: 20%;
                padding: 12px;
                cursor: pointer;
                .vitem-cover{
                    width: 100%;
                    height: 0;
                    padding-top: 136.14%;
                    background: #f8f8f8;
                    margin-bottom: 20px;
                    border-radius: 12px;
                    overflow: hidden;
                    position: relative; 
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .vitem-tag{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 6px 12px;
                        background: #999999;
                        border-radius: 12px 0px 12px 0px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                } 
                .vitem-name{
                    font-size: 18px;
                    color: #FFFFFF;
                }
                .vitem-desc{
                    margin-top: 7px;
                    line-height: 1.4;
                    font-size: 14px;
                    color: #888888;
                }
                &:hover{
                    .vitem-name{color: #FF2A2A !important;}
                }
            }
        }
</style>
