<template>
	<div class="header">
			<p v-for="(item,index) of headerArr" :key="index" @click="handleGo(item.value)">
				<template v-if="item.value==0">
					<img class="header-img" :src="item.label" alt="图片">
				</template>
				<template v-else>
					<span>{{ item.label }}</span>					
				</template>
			</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				headerArr: [{label:'首页',value:1},{label:'全部',value:2},{label:'音乐',value:3}]
			}
		},
		watch:{
			$route:{
				handler({name},o){
					if(name=='detail'){
						this.headerArr= [
						{label:'视频首页',value:1},
						{label:'音乐',value:3},
						{label:'网络剧（片）',value:4},
						]
					}else{
						this.headerArr= [{label:'首页',value:1},{label:'全部',value:2},{label:'音乐',value:3},{label:'网络剧（片）',value:4},]
					}
				},
				immediate:true
			}
		},
		methods:{
			handleGo(val){
				if(val==4){
					this.$router.replace({path:'/home'})
				}else{
					window.open('http://a8m.zwjiandu.cn/')
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.header{
	background: #2c2c2c;
	color: white;
	line-height: 3;
	padding: 0 450px 0;
	display:flex;
	gap: 0 50px;
	.header-img{
		margin-top: 2px;
		height: 40px;
	}
	p,text{
		cursor: pointer;
	}
}
</style>