/**
 * api接口的统一封装
 */
import {get,post} from './request.js';



function movieInfApi(data){   //单集
    return get('/movies/episode',data)
}
function movieApi(data){   //短剧详情
    return get('/movies/detail',data)
}
function getcodeApi(data){   //获取短信验证码
    return get('/auth/sms/code',data)
}
function loginApi(data){   //登录
    return post('/auth/login',data)
}
function bannerApi(data){   //banner
    return get('/swipers',data)
}
function navApi(data){   //分类
    return get('/categories',data)
}
function navInfoApi(data){   //分类详情
    return get('/movies',data)
}
function tuijianApi(data){   //推荐
    return get('/recommends',data)
}
function useInfoApi(data){   //用户信息
    return get('/session',data)
}
function historyfoApi(data){   //观看记录
    return get('/me/histories',data)
}
function favoritesfoApi(data){   //收藏记录
    return get('/me/favorites',data)
}
function addfavoritfoApi(data){   //添加收藏
    return post('/me/favorites',data)
}
function registerApi(data){   //注销
    return post('/me/un-register',data)
}
function orderApi(data){   //订单列表
    return get('/orders/prices',data)
}
function ordersList(data){   //消费记录
    return get('/orders',data)
}
function ordersPay(data){   //下单
    return post('/orders',data)
}
function orderStatus(data) { //查询订单状态
    return get('/orders/status',data)
}
export {
    movieInfApi,
    movieApi,
    getcodeApi,
    bannerApi,
    navApi,
    navInfoApi,
    loginApi,
    useInfoApi,
    historyfoApi,
    favoritesfoApi,
    addfavoritfoApi,
    registerApi,
    orderApi,
    tuijianApi,
    ordersPay,
    ordersList,
    orderStatus
}
