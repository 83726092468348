import router from '../router'
import Cookies from 'js-cookie'
const TokenKey = 'Authorization'

function getToken() {
//     return localStorage.getItem(TokenKey)
    return Cookies.get(TokenKey)
}
function setToken(token){
//     return localStorage.setItem(TokenKey, token)
    return Cookies.set(TokenKey, token)
}
function removeToken(){
//     return localStorage.removeItem(TokenKey)
    return Cookies.remove(TokenKey)
 }
function navTo(path){
    router.push({path})
}
export {
    navTo,
    getToken,
    setToken,
    removeToken
}