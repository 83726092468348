<template>
    <div class="home-list emergency" >
            <div class="box" @click="handleGo(index+1)" v-for="(item,index) of arr" :key="index">
                    <div class="box-text">超清</div>
                    <img class="box-img" :src="item.img" alt="图片">
                    <div class="box-bottom">
                        <p class="box-name">{{ item.name }}</p>
                        <div class="box-main">
                            <div class="box-ship">
                                <img class="ship-img" :src="require('@/assets/icon_bofang.png')" alt="图片">
                                <p class="ship-text">{{ item.ship }}</p>
                            </div>
                            <!-- <p class="ship-date">{{ new Date().toLocaleString(  ).replace(/\//g,'-')    }}</p> -->
                            <p class="ship-date">{{ item.time }}</p>
                        </div>
                    </div>
            </div>
        </div>
</template>
<script setup>
    import { ref} from "vue";
    import {useRouter} from'vue-router'
	const router = useRouter()
    let arr=ref([{name:'飞吧，冰上之光',img:require('@/assets/one.png'),ship:90096,duration:'01:26:42',time:'2023-03-15 10:03:00'},
    {name:'欢迎来到瑜伽村',img:require('@/assets/two.png'),ship:78094,duration:'01:11:39',time:'2023-05-29 05:33:52'},
    {name:'神兵特攻',img:require('@/assets/three.png'),ship:45116,duration:'01:28:29',time:'2023-06-08 12:07:42'}])
    
    function handleGo(val){
        router.push({path: '/detail',query: {id:val,isMovie:true}})
    }
   
</script>
<style lang="scss" scoped>
    .home-list{
        width: 100%;
        padding: 0 450px 60px ;
        display: flex;
        gap: 0 30px;
        margin-top: 60px;
        .box-img{
            width:30vh;
            height: 15vh;
        }
        .box{
            position: relative;
        }
        .box-name{
            color: #b4d484;
            font-weight: 500;
            margin-top: 6px;
        }
        .box-main{
            display: flex;
            justify-content: space-between;
            color: #ccc;
            font-size: 14px;
            margin-top: 10px;
            .box-ship{
                display: flex;
                align-items: center;
                img{
                    width: 15px;
                    height: 15px;
                    flex-shrink: 1;
                    margin-right: 4px;
                }
                display: flex;
            }
        }
        .box-text{
            position: absolute;
            background: linear-gradient(136deg, #F7DEAA 0%, #EDBA71 100%);
            border-radius: 0px 0px 10px 10px;
            color: white;
            text-align: center;
            right: 10px;
            width: 42px;
            height: 22px;
        }
    }
  
</style>