<template>
	<div class="users">
		<!-- 内容 -->
		<div class="container">
			<div class="user-left">
				<div class="avatar">
					<p class="avatar-holder"></p>
				</div>
				<p class="user-name">
                    <span>{{userInfo.name}}</span>
<!--					<img src="../assets/icon_bianji@2x.png" style="width:22px; height:22px;margin-top: 8px;margin-left: 5px;cursor: pointer;" alt="">-->
                </p>
				<!-- <p class="user-des" v-if="!userInfo.vip_expired_at">开通VIP，全站影片免费观看</p> -->
				<!-- <p class="user-des" v-else>VIP到期时间：{{userInfo.vip_expired_at}}</p> -->
				<p class="user-opt">
					<span @click="qiehuan()">切换</span>
					<span @click="tuichu()">退出</span>
				</p>

				<!-- <p class="user-btn" @click="openNpmber()">{{!userInfo.vip_expired_at ? '开通会员' : '会员续费'}}</p> -->
				<ul class="menus">
					<li @click="handleChange(item.type)" v-for="(item,index) in menuList" :class="actType == item.type && 'item-active'" :key="item.name+index"><img :src="actType == item.type ? item.aimg : item.img" alt=""><p>{{item.name}}</p></li>
				</ul>
			</div>
			<div class="user-right">
				<div v-show="actType == 1 || actType == 2" class="his-wra">
					<ul class="liItem" v-if="hisList.length > 0">

						<li v-for="(item,index) in hisList" :key="index" style="width:25%">
							<div class="mine-theater__item" @click="goToTheaterDetailPage(item.id)">
								<div class="mine-theater__cover">
									<img :src="item.cover">
								</div>
								<div class="mine-theater__info">
									<div class="mine-theater__title elltext">{{item.name}}</div>
									<div class="mine-theater__desc elltext2">{{item.description}}
									</div>
								</div>
							</div>
						</li>
						<div style="width:100%; height: auto; text-align: center;cursor: pointer;" v-if="noMore == false && actType == 2" @click="favoritesFetch('more')">查看更多</div>
						<div style="width:100%; height: auto; text-align: center;cursor: pointer;" v-if="noMore == false && actType == 1" @click="historyFetch('more')">查看更多</div>
<!--						<div style="width:100%; height: auto; text-align: center" v-if="noMore == false" @click="favoritesFetch('more')">查看更多</div>-->
<!--						<div style="width:100%; height: auto; text-align: center" v-if="noMore == true">暂无更多</div>-->
<!--						<li v-for="(item,index) in hisList" :key="index" class="vitem-list">-->
<!--							<img :src="item.cover" alt="">-->
<!--							<p class="vitem-name">{{item.name}}</p>-->
<!--							<p class="vitem-desc">{{item.description}}</p>-->
<!--						</li>-->
					</ul>
					<div style="width:100%; text-align: center; margin-top: 30%" v-if="hisList.length <= 0">
						暂无{{actType == 1 ? '观看历史' : (actType == 2 ? '收藏记录' : '')}}
					</div>
				</div>
				<div v-show="actType==3">
					<div class="PayList">
						<div class="item">
							<span>商品名称</span>
							<span>支付渠道</span>
							<span>购买日期</span>
							<span>消费金额</span>
							<span>订单状态</span>
						</div>
						<block v-if="ordersList.length>0">
							<div class="item" v-for="item in ordersList" :key="item.created_at">
								<span style="color: #999999">{{item.mark}}</span>
								<span style="color: #999999">{{item.paid_channel == 0 ? '微信' : (item.paid_channel == 1 ? '支付宝' : '未知')}}</span>
								<span style="color: #999999">{{item.created_at}}</span>
								<span style="color: #999999">{{(item.amount * 0.01).toFixed(2)}}</span>
								<span style="color: #999999">{{item.status == 0 ? '待支付' : (item.status == 1 ? '已支付' : (item.status == 2 ? '已退款' : ''))}}</span>
							</div>
						</block>
						
						<div style="width:100%; height: auto; text-align: center" v-if="noMore" @click="ordersFetch('more')">查看更多</div>
						<div style="width:100%; height: auto; text-align: center" v-else>暂无更多</div>
					</div>
				</div>
				<div v-show="actType==4">
					<div class="kf-info">
						<p>客服电话：010-686 23209</p>
						<p>客服公众号：七悦分销服务平台</p>
						<p>客服工作时间：上午10:00-12:00  下午13:00-19:00</p>
					</div>
					<p class="kf-desc">
						关注客服公众号，在对话框内输入您在播放过程中想了解的内容
热情耐心的客服小助理将火速赶到，竭诚为您解答
					</p>
				</div>
			</div>
		</div>
		<Model :type='qdnum' v-show="ismodel" @closeChild="clickEven"/>
<!--		开通会员弹窗-->
		<payModel :numberTip='numberTip' @openNpmber="openNpmber" v-if="numberTip"></payModel>
<!--		<div class="pay_tips" v-if="numberTip">-->
<!--			<div class="pay_tips_bg"></div>-->
<!--			<div class="pay_tips_content">-->
<!--				<div class="app-header__vip-close" @click="openNpmber()">-->
<!--					<img src="../assets/icon_guanbi.png" alt="" class="model-close">-->
<!--				</div>-->
<!--				<div class="app-header__vip-header">-->
<!--					<img src="../assets/img_touxiang.png" class="app-header__vip-avater">-->
<!--					<div class="app-header__vip-title">用户97dc8152，暂未开通会员</div>-->
<!--				</div>-->
<!--				<div class="app-header__vip-body">-->
<!--					<div class="app-header__vip-goods">-->
<!--								<div class="app-header__vip-goods-item" :class="{'is-select':payPrice == index}" v-for="(item,index) in priceList" @click="actPrice(index)">-->
<!--								<div class="app-header__vip-goods-name">{{item.name}}</div>-->
<!--								<div class="app-header__vip-goods-price">-->
<!--									<div class="app-header__vip-goods-unit" style="color: #D8B55C">￥ </div>-->
<!--									<div class="app-header__vip-goods-value" style="color: #D8B55C">{{(item.price * 0.01).toFixed(2)}}</div>-->
<!--								</div>-->
<!--							</div>-->

<!--						</div>-->
<!--					<div class="app-header__vip-payment">-->
<!--						<div class="app-header__vip-payment-container">-->
<!--							<div class="app-header__vip-payment-btn" @click="payOrder(priceList[payPrice].id)">-->
<!--								<img src="../assets/icon_zhifubao@2x.png" alt="">-->
<!--								<div class="app-header__vip-payment-btn-text">-->
<!--									<span style="color: #ffffff">点击前往支付</span>-->
<!--									<span style="color: #ffffff" class="app-header__vip-payment-value">{{(priceList[payPrice].price* 0.01).toFixed(2)}}</span>-->
<!--									<span style="color: #ffffff">元</span>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->

<!--			</div>-->
<!--		</div>-->
<!--		支付宝支付-->
<!--		<div class="aliform" v-html="aliform"></div>-->
<!--		支付宝支付end-->
	</div>
</template>

<script>
	import Model from '@/components/model'
	import payModel from "../components/payModel";
	import {historyfoApi,favoritesfoApi,orderApi,ordersPay,ordersList,orderStatus,useInfoApi} from '@/utils/api'
	import {getToken,removeToken} from '../utils/utils'
	export default {
		name: 'users',
		data () {
			return {
				qdnum:0,//0注销 1登录
				ismodel:false,
				userInfo:{},
				menuList:[
					{name:'观看历史',type:1,img:require('../assets/icon_alishijilu.png'),aimg:require('../assets/icon_guankanlishi.png')},
					{name:'收藏记录',type:2,img:require('../assets/icon_shoucangjilu.png'),aimg:require('../assets/favorite-active.png')},
					{name:'消费记录',type:3,img:require('../assets/icon_xiaofeijilu.png'),aimg:require('../assets/pay-active.png')},
					{name:'联系客服',type:4,img:require('../assets/icon_lianxikefu.png'),aimg:require('../assets/erji.png')},
					{name:'注销账号',type:5,img:require('../assets/icon_zhuxiaozhanghu.png'),aimg:require('../assets/icon_zhuxiaozhanghu.png')},
				],
				actType:1,
				oldactType:1,
				hisList:[],
				numberTip:false,//会员付款弹窗
				priceList:[],//会员价格列表
				ordersList:[],//消费列表
				payPrice:0,
				aliform:'',//支付宝表单
				listPage:1,//初始化页码
				listSize:10,//每页条数
				noMore:false,//控制是否显示暂无更多

			}
		},
		components: {
			Model,
			payModel
		},
		filters: {
			accountFormat: function (val) {
				var phone = val.toString();
                var part1 = phone.slice(0, 3);
                var part2 = phone.slice(-3);
                var result = part1 + '****' + part2;
                return result;
			}
		},

		mounted () {
			// historyfoApi().then((res)=>{
			// 	this.hisList = res
			// })
			this.historyFetch()
			let info = JSON.parse(localStorage.getItem('info'))
			this.userInfo = info
			if(this.GetRequest2('out_trade_no')) //如果订单号存在
			{
				orderStatus({'id': this.GetRequest2('out_trade_no')}).then((res)=>{

					if(res.paid || res.paid == 'true')
					{
						this.getUserInfo()
					}
				})
			}


		},

		methods: {
			//查询用户信息
			getUserInfo(){
				useInfoApi().then((res)=>{
					this.userInfo = res
					let info = JSON.parse(res)
					localStorage.setItem('info',info)

				})
			},
			//获取链接参数方法
			GetRequest2(key)
			{
				var url = location.search;
				var theRequest = new Object();
				let strs = ''
				if (url.indexOf("?") != -1)
				{
					var str = url.substr(1);
					strs = str.split("&");
					for (var i = 0; i < strs.length; i++)
					{
						theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
					}
				}
				var value = theRequest[key];
				return value;
			},
			handleChange(type){

				if(type == 1){
					this.actType = type
					this.listPage = 1
					this.historyFetch()

				}else if(type == 2){
					this.actType = type
					this.listPage = 1
					// favoritesfoApi().then((res)=>{
					// 	this.hisList = res.data
					// })
					this.favoritesFetch()
				}else if(type == 3){
					//消费记录
					this.actType = type
					this.listPage = 1
					this.ordersFetch()
				}else if(type == 4){
					//联系客服 缺少选中icon
					this.actType = type
				} else if(type == 5){
					this.qdnum = 0
					this.ismodel = true
				}
			},
			qiehuan(){
				//切换
				this.qdnum = 2
				this.ismodel = true
			},
			tuichu(){
				// 退出
				removeToken()
				localStorage.removeItem('info')
				this.$router.push({name:'home'})
			},
			clickEven(val){
				this.ismodel = false
				if(val.type.value == 2){
					location.reload()
				}
			},
			//开通会员点击事件
			openNpmber(){
				if(this.numberTip == false)
				{
					this.numberTip = true
				}
				else
				{
					this.numberTip = false
				}
			},
			//消费记录接口
			ordersFetch(e){
				let page;//页码
				let size = this.listSize;//每页条数
				if(e == 'more')
				{
					page = this.listPage + 1
					this.listPage = page
					size = this.listSize
				}
				else
				{
					page = this.listPage
					size = this.listSize
				}

				ordersList({'page':page,'size':size }).then((res) =>{

					// if(res.data.length >= this.listSize)
					// {
					// 	this.noMore = false
					// }
					// else
					// {
					// 	this.noMore = true
					// }
					this.noMore = res.more
					if(res.page == 1){
						this.ordersList = res.data
					}else{
						this.ordersList = this.ordersList.concat(res.data)
					}

				})
			},
			historyFetch(e){
				let page;//页码
				let size = this.listSize;//每页条数
				if(e == 'more')
				{
					page = this.listPage + 1
					this.listPage = page
					size = this.listSize
				}
				else
				{
					page = this.listPage
					size = this.listSize
				}
				historyfoApi({'page':page,'size':size }).then((res)=>{

					if(res.length >= this.listSize)
					{
						this.noMore = false
					}
					else
					{
						this.noMore = true
					}
					if(e == 'more')
					{
						this.hisList = this.hisList.concat(res)
					}
					else
					{
						this.hisList = res
					}

				})
			},
			favoritesFetch(e){
				let page;//页码
				let size = this.listSize;//每页条数
				if(e == 'more')
				{
					page = this.listPage + 1
					this.listPage = page
					size = this.listSize
				}
				else
				{
					page = this.listPage
					size = this.listSize
				}
				favoritesfoApi({'page':page,'size':size }).then((res)=>{

					if(res.data.length >= this.listSize)
					{
						this.noMore = false
					}
					else
					{
						this.noMore = true
					}
					if(e == 'more')
					{
						this.hisList = this.hisList.concat(res.data)
					}
					else
					{
						this.hisList = res.data
					}

				})
			},

			actPrice(e){
				this.payPrice = e
			},
			goToTheaterDetailPage(e){ //跳转到详情方法
				this.$router.push({name:'detail',query: {id:e}})
			},


		},
		beforeRouteEnter (to, from, next){
			// alert('666')
			next()
		},
	}
</script>

<style lang="scss" scoped>
	.vipSty{
		width: 100%;
		height: auto;
		text-align: center;
	}
	/*!*观看历史*! 收藏记录*/
	@media screen and (min-width: 1920px){
		.mine-theater__item {
			width: 100%;
		}
	}
	.liItem{
		display: flex;
		flex-wrap: wrap;
		margin: -30px -10px
	}
	.mine-theater__item{
		padding: 30px 10px;
		cursor: pointer;
		width: 100%;
		position: relative;
		box-sizing: border-box;
		&:hover{
			.mine-theater__title{color: red !important;}
		}
	}
	.mine-theater__cover{
		width: 100%;
		height: 0;
		padding-top: 133.33%;
		background: #f8f8f8;
		margin-bottom: 20px;
		border-radius: 20px;
		overflow: hidden;
		position: relative;
	}
	.mine-theater__cover img {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.mine-theater__episode {
		height: 40px;
		line-height: 40px;
		font-size: 20px;
		color: #FFFFFF;
		background: #999999;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
		padding: 0 16px;
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.mine-theater__title {
		line-height: 1;
		font-size: 24px;
		font-weight: 600;
		color: #333333;
	}
	.mine-theater__desc {
		line-height: 1.3;
		font-size: 16px;
		color: #888888;
		margin-top: 7px;
	}
	/*消费记录*/
		.PayList{

			align-items: center;
		}
	.PayList .item{
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.PayList .item  span:nth-child(1) {
		width: 18%;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		padding: 28px 46px;
		box-sizing: border-box;
	}
	.PayList .item  span:nth-child(2) {
		width: 18%;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		padding: 28px 46px;
		box-sizing: border-box;
	}
	.PayList .item  span:nth-child(3) {
		width: 28%;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		padding: 28px 46px;
		box-sizing: border-box;
	}
	.PayList .item  span:nth-child(4) {
		width: 18%;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		padding: 28px 46px;
		box-sizing: border-box;
	}
	.PayList .item  span:nth-child(5) {
		width: 18%;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		padding: 28px 46px;
		box-sizing: border-box;
	}
	/*消费记录*/
	/*会员弹窗样式*/
	.pay_tips{
		width: 100%;
		height: 100%;
		/*background: red;*/
		position:fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
	}
	.pay_tips_bg{
		width: 100%;
		height: 100%;
		/*background: red;*/
		position:absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.4);
	}
	.pay_tips_content{
		width: 880px;
		background: #FFFFFF;
		position: relative;
		z-index: 20;
	}
	.app-header__vip-close {
		position: absolute;
		top: 17px;
		right: 17px;
		width: 21px;
		height: 21px;
		cursor: pointer;
	}
	.app-header__vip-close img {
		width: 100%;
		height: 100%;
	}
	.app-header__vip-header {
		height: 140px;
		padding: 0 32px;
		background: #FFF4D9;
		display: flex;
		align-items: center;
	}
	.app-header__vip-avater {
		width: 88px;
		height: 88px;
		border-radius: 50%;
		margin-right: 12px;
	}
	.app-header__vip-title {
		font-size: 24px;
		color: #B88723;
	}
	.app-header__vip-body {
		padding: 25px 20px 88px;
	}
	.app-header__vip-goods {
		display: flex;
		flex-wrap: wrap;
		margin: -10px -10px 30px;
	}
	.app-header__vip-goods-item {
		margin: 10px;
		width: 150px;
		height: 130px;
		border: 2px solid #D8B55C;
		border-radius: 12px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.app-header__vip-goods-name {
		line-height: 1;
		font-size: 20px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 20px;
	}
	.app-header__vip-goods-price {
		line-height: 1;
		font-weight: 600;
		color: #D8B55C !important;
		display: flex;
		align-items: flex-end;
	}
	.app-header__vip-goods-value {
		font-size: 36px;
	}
	.app-header__vip-payment {
		border: 2px solid #F0F0F0;
		border-radius: 12px;
		overflow: hidden;
		display: flex;
		align-items: stretch;
	}
	.app-header__vip-payment-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 60px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.app-header__vip-payment-btn {
		height: 60px;
		background: #469DE2;
		border-radius: 30px;
		font-size: 20px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 1.4;
		display: flex;
		align-items: center;
		padding: 0 43px;
		cursor: pointer;
	}
	.app-header__vip-payment-btn-text {
		display: flex;
		align-items: flex-end;
	}
	.app-header__vip-payment-btn img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.app-header__vip-payment-value {
		line-height: 1;
		font-size: 30px;
		padding: 0 8px;
	}
	 .is-select {
		background-color: #D8B55C;
		 color: #ffffff !important;
	}
	 .is-select .app-header__vip-goods-name{
		 color: #ffffff !important;
	 }
	.is-select .app-header__vip-goods-price .app-header__vip-goods-unit{
		color: #ffffff !important;
	}
	.is-select .app-header__vip-goods-price .app-header__vip-goods-value{
		color: #ffffff !important;
	}
	/*会员弹窗样式end*/
.users{
	min-width: 1200px;
	margin: 36px 160px 80px;
	background-color: #FFFFFF;
}
.container {
		display: flex;
		width: 100%;
		margin: 0 auto;
	}
/* 用户中心 */
.user-left {
	width: 300px;
    height: 100%;
    background: #F8F8F8;
    border-radius: 8px;
	margin-right: 30px;
	min-height: 500px;
	padding: 0 20px 90px;
	.avatar {
		width: 100%;
		height: 150px;
		padding: 40px 0 20px;
		position: relative;
		.avatar-holder {
			width: 90px;
			height: 90px;
			border-radius: 100px;
			margin: 0 auto;
			display: block;
			background: url('../assets/img_touxiang.png') no-repeat center center/100% 100%;
		}
	}
	.user-name{
		display: flex;
		justify-content: center;
		align-content: center;
		font-size: 26px;
		font-weight: 500;
		color: #333333;
		margin-bottom: 10px;
	}
	.user-des{
		font-size: 14px;
		color: #666666;
		margin-bottom: 30px;
		text-align: center;
	}
	.user-opt{
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		span{
			font-size: 16px;
			font-weight: 500;
			color: #333333;
			/*co*/
			cursor: pointer;
		}
		span:first-child{
			margin-right: 38px;
		}
	}
	.user-btn{
		width: 148px;
		height: 44px;
		text-align: center;
		line-height: 44px;
		background: #DBB85D;
		border-radius: 22px;
		margin: 0 auto 30px;
		font-size: 16px;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.menus {
		li {
			position: relative;
			display: flex;
			align-items: center;
			padding: 20px 3px;
			border-bottom:  1px solid #DFDFDF;
			img{
				width: 30px;
				height: 30px;
				margin-right: 12px;
			}
			p{
				font-size: 20px;
				font-weight: 400;
				color: #333333;
			}
			color: #999999;

			cursor: pointer;
			&:hover{
				:before{
					height: 40%;
				}
			}
			:before{
				position: absolute;
				left: -20px;
				top: 30%;
				display: block;
				content: '';
				width: 2px;
				height: 0;
				background-color: #FF2929;
				transition: all .3s;
			}
		}
		.item-active {
			p{color: #FF2929;}
		}
	}

}
.user-right {
	flex: 1;
	padding: 0 20px;
	min-height: 570px;
	.kf-info{
		width: 610px;
		height: 108px;
		border-radius: 10px;
		border: 2px solid #FF2A2A;
		padding: 12px 77px;
		margin: 345px auto 30px;
		p{
			font-size: 20px;
			font-weight: 400;
			color: #FF2A2A;
			line-height: 28px;
			text-align: center;
		}
	}
	.kf-desc{
		width: 504px;
		height: 50px;
		font-size: 18px;
		font-weight: 400;
		color: #999999;
		line-height: 25px;
		margin: 0 auto;
		text-align: center;
	}
}

</style>
