	import axios from 'axios'
	import Qs from 'qs';
	import router from '../router/index.js'
	import {getToken,removeToken} from '../utils/utils'

	// 环境的切换
	if (process.env.NODE_ENV == 'development') {    
		axios.defaults.baseURL = 'https://web-api.zhangwenjiandu.com';
	}else if (process.env.NODE_ENV == 'production') {    
		axios.defaults.baseURL = 'https://web-api.zhangwenjiandu.com';
	}

	const service = axios.create({
		timeout: 50000 
	})
  	// 2.axios的拦截器
  	service.interceptors.request.use(
	  (config) => {
		  //设置请求头
		  if (getToken()) { //判断本地缓存的token是否存在
				config.headers['Authorization'] = `Bearer ${getToken()}`
		  }
   
		  return config
	  },
	  (error) => { //token不存在，设置为网络报错
		  return Promise.reject(error)
	  }
  	)
  // 2.2.响应拦截
  	service.interceptors.response.use(
	  (res) => { //响应处理
		  if (res.status === 200) { //响应码200请求成功
			  if (res.data.code == '2000') { //接口请求成功
				  return Promise.resolve(res.data)
			  } else if (res.data.code == '401' || res.data.code == '4010') {//token验证失败，根据自己实际的修改
				  //清除token
				  removeToken()
				//   localStorage.removeItem('Authorization')
				  //跳转到登录页面
				//   router.push('/login')
			  } else {
				  //Message.error(res.data.msg);
			  }
			  return Promise.reject(res.data)
		  } else {
			  return Promise.reject(res.data)
		  }
		  // return res
	  },
	  (error) => {
		  
		  if (error.status === 401) {
			  //Message.error('请重新登录')
			  //清楚token
			  removeToken()
			//   localStorage.removeItem('Authorization')
			  //跳转到登录页面
			//   router.push('/login')
		  }
		  return Promise.reject(error)
	  }
  )
	/**
	 * get 请求方法
	 * @param url
	 * @param params
	 * @returns {Promise}
	 */
	export function get(url, params = {}) {
		return new Promise((resolve, reject) => {
			service.get(url, {
					params: params
				})
				.then(response => {
					if(response.code == 2000 ){
						resolve(response.data)
					}
				})
				.catch(err => {
					reject(err)
				})
		})
	}

	/**
	 * post 请求方法
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */
	export function post(url, data = {}) {
		return new Promise((resolve, reject) => {
			service.post(url,Qs.stringify(data)).then(response => {
				if(response.code == 2000 ){
					resolve(response.data)
				}
			})
			.catch(err => {
				reject(err)
			})
		})
	}