<template>
    <div class="pay_tips" v-if="numberTip">
        <div class="pay_tips_bg"></div>
        <div class="pay_tips_content">
            <div class="app-header__vip-close" @click="openNpmber()">
                <img src="../assets/icon_guanbi.png" alt="" class="model-close">
            </div>
            <div class="app-header__vip-header">
                <img src="../assets/img_touxiang.png" class="app-header__vip-avater">
                <div class="app-header__vip-title">{{userInfo.name}}，暂未开通会员</div>
            </div>
            <div class="app-header__vip-body">
                <div class="app-header__vip-goods">
                    <div class="app-header__vip-goods-item" :class="{'is-select':payPrice == index}" v-for="(item,index) in priceList" @click="actPrice(index)">
                        <div class="app-header__vip-goods-name">{{item.name}}</div>
                        <div class="app-header__vip-goods-price">
                            <div class="app-header__vip-goods-unit" style="color: #D8B55C">￥ </div>
                            <div class="app-header__vip-goods-value" style="color: #D8B55C">{{(item.price * 0.01).toFixed(2)}}</div>
                        </div>
                    </div>

                </div>
                <div class="app-header__vip-payment" v-show="actMoney.id">
                    <div class="app-header__vip-payment-container">
                        <div class="app-header__vip-payment-btn" @click="payOrder(actMoney.id)">
                            <img src="../assets/icon_zhifubao@2x.png" alt="">
                            <div class="app-header__vip-payment-btn-text">
                                <span style="color: #ffffff">点击前往支付</span>
                                <span style="color: #ffffff" class="app-header__vip-payment-value">{{(actMoney.price* 0.01).toFixed(2)}}</span>
                                <span style="color: #ffffff">元</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import {historyfoApi,favoritesfoApi,orderApi,ordersPay,ordersList} from '@/utils/api'
    export default {
        name: 'payModel',
        props: {
            numberTip: {
                type: Boolean,
                required: false
            },
            batchSelect: {
                type: Boolean,
            },
        },
        data () {

            return {
                priceList:[],
                payPrice:0,
                actMoney:{},
                userInfo:[]
            }
        },
        created(){
            // this.numberTip = false

            orderApi().then((res)=>{
                console.log(res)
                this.priceList = res
                this.actMoney = this.priceList[this.payPrice]
                // this.hisList = res.data
                // this.numberTip = true
            })
            let info = JSON.parse(localStorage.getItem('info'))
            console.log(info)
            this.userInfo = info
        },
        watch: {

        },
        filters: {

        },
        methods: {
            actPrice(e){
                this.payPrice = e
                this.actMoney = this.priceList[this.payPrice]
            },
            openNpmber(){
                // alert('666')
                // this.$emit('numberTip',false)
                this.$parent.openNpmber()
            },
            payOrder(e){ //支付宝支付
                ordersPay({"id":e}).then((res =>{
                    const div = document.createElement("divform");
                    div.innerHTML = res.form;
                    document.body.appendChild(div);
                    document.forms[0].submit();
                }))
            },
        }
    }
</script>
<style  scoped>
    .pay_tips{
        width: 100%;
        height: 100%;
        /*background: red;*/
        position:fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .pay_tips_bg{
        width: 100%;
        height: 100%;
        /*background: red;*/
        position:absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }
    .pay_tips_content{
        width: 880px;
        background: #FFFFFF;
        position: relative;
        z-index: 20;
    }
    .app-header__vip-close {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 21px;
        height: 21px;
        cursor: pointer;
    }
    .app-header__vip-close img {
        width: 100%;
        height: 100%;
    }
    .app-header__vip-header {
        height: 140px;
        padding: 0 32px;
        background: #FFF4D9;
        display: flex;
        align-items: center;
    }
    .app-header__vip-avater {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .app-header__vip-title {
        font-size: 24px;
        color: #B88723;
    }
    .app-header__vip-body {
        padding: 25px 20px 88px;
    }
    .app-header__vip-goods {
        display: flex;
        flex-wrap: wrap;
        margin: -10px -10px 30px;
    }
    .app-header__vip-goods-item {
        margin: 10px;
        width: 150px;
        height: 130px;
        border: 2px solid #D8B55C;
        border-radius: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .app-header__vip-goods-name {
        line-height: 1;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 20px;
    }
    .app-header__vip-goods-price {
        line-height: 1;
        font-weight: 600;
        color: #D8B55C !important;
        display: flex;
        align-items: flex-end;
    }
    .app-header__vip-goods-value {
        font-size: 36px;
    }
    .app-header__vip-payment {
        border: 2px solid #F0F0F0;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        align-items: stretch;
    }
    .app-header__vip-payment-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 60px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .app-header__vip-payment-btn {
        height: 60px;
        background: #469DE2;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.4;
        display: flex;
        align-items: center;
        padding: 0 43px;
        cursor: pointer;
    }
    .app-header__vip-payment-btn-text {
        display: flex;
        align-items: flex-end;
    }
    .app-header__vip-payment-btn img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .app-header__vip-payment-value {
        line-height: 1;
        font-size: 30px;
        padding: 0 8px;
    }
    .is-select {
        background-color: #D8B55C;
        color: #ffffff !important;
    }
    .is-select .app-header__vip-goods-name{
        color: #ffffff !important;
    }
    .is-select .app-header__vip-goods-price .app-header__vip-goods-unit{
        color: #ffffff !important;
    }
    .is-select .app-header__vip-goods-price .app-header__vip-goods-value{
        color: #ffffff !important;
    }
</style>
