<template>
	<div class="model-wrap" @click="handleClose">
        <p class="model-toast" v-show="msg">{{msg}}</p>
        <div class="model-con" @click.stop="">
            <img src="../assets/icon_guanbi.png" alt="" class="model-close" @click.stop="handleClose">
            <p class="model-tit">{{type == 1 || type == 2 ? '短信登录' : '注销账号'}}</p>
            <div class="model-input">
                <input type="text" placeholder="请输入手机号" v-model="mobile">
            </div>
            <div class="model-input model-code">
                <input type="text" placeholder="请输入验证码" v-model="yzmcode">
                <p :class="['model-codebtn',`${isClickSend && 'codegray'}`]" @click="sendCode">{{ !isClickSend ? "发送验证码" : `重新获取中(${codeNum})s` }}</p>
            </div>
            <div class="model-tip" v-show="type == 0" @click="zxconf = !zxconf">
                <p :class="['model-noc',zxconf && 'model-cho']"></p>
                <p class="model-tiptext">我已确认，账号一旦注销不可恢复，请谨慎操作</p>
            </div>
            <p class="model-btn" v-show="type == 0 || type == 1  || type == 2" @click="handleBtn">{{type == 1 || type == 2 ? '登录':'确认注销'}}</p>
        </div>
    </div>
</template>

<script setup>
	import { ref,reactive,watch,onMounted,onUnmounted,toRefs,defineEmits } from "vue";
	import {navTo,getToken,removeToken,setToken} from '../utils/utils'
    import {getcodeApi,loginApi,registerApi,useInfoApi} from '@/utils/api'
    import Cookies from 'js-cookie'
	import {useRouter} from'vue-router'
	const router = useRouter()
    const props = defineProps({
        type:Number
    })
    const {type} = toRefs(props)

    // 使用defineEmits创建名称，接受一个数组
    const emit = defineEmits(['closeChild'])
    const handleClose = (info)=> {
        emit('closeChild',{statu:'false',info,type})
        mobile.value = '' 
        yzmcode.value = '' 
    }
    let zxconf = ref(false)
	// 定时器
    let clearId = ref(null);
    // 倒计时时间
    let codeNum = ref(60);
    // 手机号
    let mobile = ref("");
    // 验证码
    let yzmcode = ref('');
    let msg = ref('');
    watch(msg, (newValue, oldValue) => {
        if(newValue != oldValue){
            setTimeout(()=>{
                msg.value = ''
            },3000)
        }
    });
    onMounted(()=>{

    })
    onUnmounted(()=>{
       
    })
    // 是否发送了验证码 防止连点
    let isClickSend = ref(false);// 发送验证码
    const sendCode = async () => {
        if (isClickSend.value || codeNum.value != 60) return;
        var reg3 = /^0?(13|15|17|18|14|19)[0-9]{9}$/   //请输入正确的手机号
        if (!reg3.test(mobile.value)) {
            msg.value = '请输入正确的手机号'
            return
        }
        try{
            let {code} = await getcodeApi({mobile:mobile.value});
            if(code){
                isClickSend.value = true;
                // yzmcode.value = code
                clearId.value = setInterval(() => {
                    codeNum.value--;
                    if (codeNum.value == 0) {
                        clearInterval(clearId.value);
                        codeNum.value = 60;
                        isClickSend.value = false;
                    }
                }, 1000);
            }
        }catch(err){
            msg.value = err.message
        }

    };
    const handleBtn = async()=>{
        if(type.value == 1 || type.value == 0 || type.value == 2){
            if(!mobile.value){
                msg.value = '请填写手机号！'
                return
            }
            if(!yzmcode.value){
                msg.value = '请填写验证码！'
                return
            }

            try{
                if(type.value == 0){
                    if(!zxconf.value){
                        msg.value = '请勾选确认选择框！'
                        return 
                    }
                    let res = await registerApi({mobile:mobile.value,code:yzmcode.value})
                    removeToken()
                    localStorage.removeItem('info')
                    msg.value = '注销成功！'
                    Cookies.remove('videohis')
                    handleClose()
                    router.push({path:'/'})
                }else{
                    try {
                        let res = await loginApi({mobile:mobile.value,code:yzmcode.value})
                        setToken(res.token)
                        useInfoApi().then((res)=>{
                            localStorage.setItem('info',JSON.stringify(res))
                            handleClose(res)
                        })
                        msg.value = '登录成功！'
                    }catch(err){
                        console.log(err)
                        msg.value = err.message
                    }
                    
                }
            }catch(err){
                msg.value = err.message
            }
        }else if(type.value == 1){
            registerApi
        }
    }
    onMounted(() => {clearInterval(clearId)})
</script>

<style lang="scss" scoped>
    .model-wrap{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 99;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .model-toast{
        position: absolute;
        top: 120px;
        background: #000000;
        border-radius: 27px;
        padding: 6px 36px;
        font-size: 28px;
        height: 54px;
        color: #FFFFFF;
        transition: all .5s;
    }
	.model-con{
        position: absolute;
        top: 180px;
        width: 680px;
        background-color: #FFFFFF;
        padding: 40px 60px;
        border-radius: 20px;
        .model-close{
            position: absolute;
            width: 38px;
            height: 38px;
            top: 20px;
            right: 23px;
        }
        .model-tit{
            width: 100%;
            text-align: center;
            height: 56px;
            font-size: 40px;
            font-weight: 500;
            color: #333333;
            line-height: 56px;
            margin-bottom: 30px;
        }
        .model-input{
            position: relative;
            width: 560px;
            height: 88px;
            background: #F6F7F9;
            border-radius: 44px;
            overflow: hidden;
            margin-bottom: 20px;
            input{
                width: 100%;
                height: 100%;
                background: #F6F7F9;
                border: none;
                outline: none;
                font-size: 28px;
                font-weight: 400;
                color: #999999;
                padding: 24px 50px;
            }
        }
        .model-code{
            cursor: pointer;
            margin-bottom: 50px;
            .model-codebtn{
                position: absolute;
                right: 50px;
                top: 24px;
                font-size: 28px;
                font-weight: 500;
                color: #666666;
            }
            .codegray{
                color: #CDCDCD;
            }
        }
        .model-btn{
            cursor: pointer;
            width: 560px;
            height: 88px;
            line-height: 88px;
            background: linear-gradient(270deg, #FF322B 0%, #FF6E6E 100%);
            border-radius: 49px;
            font-size: 40px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
        }
        .model-tip{
            cursor: pointer;
            margin: -30px 0 30px;
            font-size: 22px;
            p{color: #666666;}
            display: flex;
            align-items: center;
            .model-noc{
                width: 20px;
                height: 20px;
                border-radius: 10px;
                border: 2px solid #979797;
                margin-right: 16px;
            }
            .model-cho{
                background: url('../assets/icon_queren.png') no-repeat center center/20px 20px;
                border: none;
            }
        }
    }
</style>
